
$food-table-border: #fff5ef;
$food-table-bgc: #fff5ef;
$food-table-10: darken(#fff5ef, 10);
$food-table-5: darken(#fff5ef, 5);

.food-page{
  padding: 1px 0;
  background-color: $food-table-bgc;
}

main .food-menus{
  table{
    border: none;
    border-collapse: collapse;
    thead{
      tr{
        th{
          background: $food-table-10;
          border: 4px solid $food-table-border;
          &:nth-child(1),
          &:nth-child(2){
            background: none;
          }
        }
      }
    }
    tbody{
      tr{
        td{
          border: 4px solid $food-table-border;
          background: $food-table-5;
        }
        td:first-child{
          border-left: none;
          //background: darken(#fff5ef, 7);
          border-right: none;
        }
        &:nth-child(even){
          background: none;
        }
        .day-name{
          background: $food-table-10 !important;
          border-right: none;
        }
      }
      &:nth-child(even){
        tr td{
          background: darken(#fff5ef, 7);
        }
      }
    }
  }
}

.food-nwl{
  background: $food-table-10;
  padding: 20px;
  border: 2px solid $food-table-border;
  margin-bottom: 40px;
  h3{
   // display: none;
   margin: 0 0 15px 0;
  }
  .form-horizontal-inner{
    display: flex;
    gap: 2rem;
    align-items: flex-end;
    width: 100%;
  }
  .form-horizontal{
    width: calc(100% - 350px);
  }
  .nwl-text{
    min-width: 350px;
  }
  .form-group{
    width: 100%;
    &.form-group-submit{
      width: auto;
    }
  }
  input[type="text"]{
    height: 45px;
  }
  .NewsletterSubscription{
    display: flex;
    align-items: center;
    gap: 2rem;
  }
}

.food-menu{
  display: none;
}